import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const RegisterTopNavigation = () => {

    const navigate = useNavigate();

    return (
        <div className="container-fluid login__top-navigation">
            <div className="row align-items-center">
                <div className="col-3">
                    <button className="btn" onClick={() => navigate(-1)}>
                        <i className="fas fa-chevron-left"></i>
                    </button>
                </div>
                <div className="col-9 text-end">
                    <p className="login__light-text text-end" style={{ margin: 0 }}>
                        Already have an account? <Link to="/auth/login">Sign in</Link></p>
                </div>
            </div>
        </div>
    )
}

export default React.memo(RegisterTopNavigation);
