import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const ForgotPasswordNav = () => {

    const navigate = useNavigate();

    return (
        <div className="container-fluid login__top-navigation">
            <div className="row align-items-center">
                <div className="col-3">
                    <button className="btn" onClick={() => navigate(-1)}>
                        <i className="fas fa-chevron-left"></i>
                    </button>
                </div>
                <div className="col-9 text-end">
                    <p className="login__light-text text-end" style={{ margin: 0 }}>
                        Don&#39;t have an account? <Link to="/auth/register">Sign up</Link></p>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ForgotPasswordNav);
