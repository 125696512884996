export const types = {
    authLogin: '[auth] Login',
    authLogout: '[auth] Logout',
    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start login',
    authStartRegister: '[auth] Start Register',
    authStartStartTokenRenew: '[auth] Start token renew',


    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    uiStartLoading: '[UI] Start Loading',
    uiFinishLoading: '[UI] Finish Loading',

    dashboardShowEditMode: '[Dashboard] Show Edit Mode',
    dashboardHideEditMode: '[Dashboard] Hide Edit Mode',
    dashboardShowChart: '[Dashboard] Show Chart',

    reportBuilderLoadSlides: '[Report Builder] Load Slides',
    reportBuilderSelectCurrentSlide: '[ReportBuilder] Select Current Slide',
    reportBuilderUpdateSlide: '[ReportBuilder] Update Slide',
    reportBuilderUpdateSelectedSlide: '[ReportBuilder] Update Selected Slide',
    reportBuilderZoomSlide: '[ReportBuilder] Zoom Slide',
    reportBuilderSelectTool: '[ReportBuilder] Select Tool',
    reportBuilderUndoSelectedSlideChange: '[ReportBuilder] Undo Selected Slide',
    reportBuilderRedoSelectedSlideChange: '[ReportBuilder] Redo Selected Slide',

    dealsSetBoardItems: '[Deals] Set Board Items',
    dealsSetActiveBoardItem: '[Deals] Set Active Board Item',
    dealsUpdateBoardItemActions: '[Deals] Update Board Item Actions',

    accountsSetActive: '[Accounts] Set Active',

    eventsGet: '[Events] Get',
    eventsAdd: '[Events] Add',
    eventsUpdate: '[Events] Update',
};