import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import { AuthRoutes } from './AuthRoutes';
import { DashboardRoutes } from './DashboardRoutes';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';

export const AppRouter = () => {

    return (
        <Router>
            <Routes>
                <Route path="/auth/*" element={
                    <PublicRoute isAuthenticated={true}>
                        <AuthRoutes />
                    </PublicRoute>
                } />

                <Route path="/*" element={
                    <PrivateRoute isAuthenticated={true}>
                        <DashboardRoutes />
                    </PrivateRoute>
                } />
            </Routes>
        </Router>
    )
}
