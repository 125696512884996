import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

const RegisterForm = () => {

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();
    const navigate = useNavigate();

    const onSubmit = () => {
        navigate('/today');
    }


    return (
        <form className="mt-2 login__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-6 mt-4">
                    <label
                        htmlFor="first_name"
                        className="form-control-label login__label">First name</label>
                    <input
                        id="first_name"
                        autoComplete="off"
                        type="text"
                        className={`form-control ${errors.first_name && 'is-invalid'}`}
                        {...register('first_name', { required: true, minLength: 3 })} />
                </div>
                <div className="col-6 mt-4">
                    <label
                        htmlFor="last_name"
                        className="form-control-label login__label">Last name</label>
                    <input
                        id="last_name"
                        autoComplete="off"
                        type="text"
                        className={`form-control ${errors.last_name && 'is-invalid'}`}
                        {...register('last_name', { required: true, minLength: 3 })} />
                </div>
                <div className="col-12 mt-4">
                    <label
                        htmlFor="email"
                        className="form-control-label login__label">Email address</label>
                    <input
                        id="email"
                        type="email"
                        className={`form-control ${errors.email && 'is-invalid'}`}
                        {...register('email', { required: true, pattern: /^\S+@\S+$/i })} />
                </div>
                <div className="col-12 mt-4">
                    <label
                        htmlFor="password"
                        className="form-control-label login__label">Password</label>
                    <input
                        id="password"
                        type="password"
                        className={`form-control ${errors.password1 && 'is-invalid'}`}
                        {...register('password1',
                            { required: true, pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/i })} />
                    <div className="form-text text-sm">
                        Password must contain a lowercase, an uppercase, a number and minimun 8 letters.
                    </div>


                </div>
                <div className="col-12 mt-4">
                    <div className="form-check">
                        <input
                            className={`form-check-input ${errors.accept && 'is-invalid'}`}
                            type="checkbox" id="flexCheckDefault"
                            {...register('accept', { required: true })} />
                        <label className="form-check-label login__light-text text-start" htmlFor="flexCheckDefault">
                            Creating an account means you’re ok with our
                            {' '} <Link to="/">Terms of Service </Link>  and <Link to="/"> Privacy Policy</Link>.
                        </label>
                    </div>
                </div>

                <div className="col-12 mt-4 d-grid gap-2">
                    <button
                        className="btn btn-block text-center button__blue"
                        disabled={isSubmitting}>Sign Up</button>
                </div>
            </div>
        </form>
    )
}

export default React.memo(RegisterForm);
