import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ProfilePicture from 'assets/user.png';
import { startLogout } from 'actions/auth';
import { ReactComponent as ArrowDown } from 'assets/dropdown-arrow.svg';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import SearchInput from 'shared/components/inputs/SearchInput';


const NavbarUi = () => {

  const dispatch = useDispatch();


  const handleLogout = () => {
    dispatch(startLogout());
  }

  return (
    <Navbar className="pe-3 d-none d-md-block pt-3" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto align-items-center">
          <SearchInput
            placeholder='Search'
            className='me-2 navbar__search-box'
            contentClassName='navbar__search-box-wrapper' />
          <button className='navbar__plus-btn'>
            <PlusIcon />
          </button>
          <NavDropdown title={
            <>
              <div className="navbar__avatar-container" style={{ backgroundImage: `url(${ProfilePicture})` }}>
                </div>
            </>
          } id="basic-nav-dropdown">
            <span className="navbar__email">ajbruno@quotapath.com</span>
            <NavDropdown.Item as={Link} to={'/dashboard'} className="navbar__item">Dashboard</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={'/settings'} className="navbar__item">Account</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={'/settings?workspace=active'} className="navbar__item">
              Add members
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to={'/help'} className="navbar__item">Help Center</NavDropdown.Item>
            <NavDropdown.Divider />
            <span className="navbar__email">Workspace</span>
            <NavDropdown.Item as={Link} to={'/settings?workspace=active'} className="navbar__item">
              <i className="fas fa-circle green me-1 dropdown-dot"></i>
              QuotaPath
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleLogout} className="navbar__item">Log out</NavDropdown.Item>
            <ArrowDown className="navbar__arrow-down" />
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default React.memo(NavbarUi);
