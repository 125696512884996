
import { Reducer } from 'redux';

import { IDealBoardItem } from 'interfaces/dealBoardItem';
import { types } from 'types/types';
import { DealsData } from '../helpers/dealsData';

interface DealState {
    boardItems: IDealBoardItem[];
    activeBoardItem?: number;
}

type DealAction = {
    type: string;
    payload?: any;
}

const initialValues = {
    boardItems: DealsData,
};

export const dealReducer: Reducer<DealState, DealAction> =
    (state: DealState = initialValues, action: DealAction) => {
        switch (action.type) {
            case types.dealsSetBoardItems:
                return {
                    ...state,
                    boardItems: action.payload,
                }
            case types.dealsSetActiveBoardItem:
                return {
                    ...state,
                    activeBoardItem: action.payload,
                }
            case types.dealsUpdateBoardItemActions:

                return {
                    ...state,
                    boardItems: state.boardItems.map(item => {
                        if (item.id === action.payload.id) {
                            return {
                                ...item,
                                actions: [action.payload.actions, ...item.actions],
                            }
                        }
                        return item;
                    }
                    ),
                }
            default:
                return state;
        }
    }