import styled from 'styled-components';

export default styled.div`
    width: 438px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 45px;
    border: 1px solid #E6E7E9;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    @media screen and (max-width: 576px) {
        width: 95%;
        padding: 12px;
    }
`;