import { types } from 'types/types';

interface UiState{
    loading: boolean;
    msgError: string;
}

type UiAction = {
    type: string;
    payload?: { msgError: string };
}
const initialState = {
    loading: false,
    msgError: '',
}

export const uiReducer = (state: UiState = initialState, action: UiAction) => {
    switch (action.type) {
        case types.uiSetError:
            return {
                ...state,
                msgError: action.payload?.msgError
            }
        case types.uiRemoveError:
            return {
                ...state,
                msgError: null
            }
        case types.uiStartLoading:
            return {
                ...state,
                loading: true
            }
        case types.uiFinishLoading:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}