import React from 'react';
import { Link } from 'react-router-dom';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useDispatch } from 'react-redux';

import Logo from 'assets/logo.png';
import GoogleIcon from 'assets/google.svg';
import LoginForm from './components/LoginForm';
import CenterRow from 'shared/components/CenterRow';
import Divider from 'shared/components/Divider';
import { startGoogleLogin } from 'actions/auth';
import AuthContainer from 'shared/components/AuthContainer';
import { LoginToast } from './components/LoginToast';

const GOOGLE_CLIENT_ID = (process.env.REACT_APP_GOOGLE_CLIENT_ID as string);


const LoginScreen = () => {

    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get('reset-confirmation');

    const dispatch = useDispatch();

    const responseGoogle = (response: any) => {
        const { tokenId, accessToken } = response;
        dispatch(startGoogleLogin(accessToken, tokenId));

    }

    const onFailure = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        console.log(response);
    }


    return (
        <>
            <div className="login__content">
                <AuthContainer>
                    <img src={Logo} alt="Handraise Logo" className="mt-3" width={221} />
                    <p className="login__small-text">Keep every customer.</p>
                    <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        render={renderProps => (
                            <button
                                className="btn btn-block mt-4 login__btn-google"
                                onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                <img src={GoogleIcon} alt="Google Logo" className="me-2" />
                                Sign in with Google
                            </button>
                        )}
                        buttonText="Sign in with Google"
                        onSuccess={responseGoogle}
                        onFailure={onFailure}
                        cookiePolicy={'single_host_origin'}
                    />

                    <CenterRow className="mt-4">
                        <Divider />
                        <div className="login__separator-text">or</div>
                        <Divider />
                    </CenterRow>
                    <LoginForm />
                    <div className="row login__custom-container">
                        <div className="col-12 mt-5 text-center">
                            <p
                                className="login__light-text">
                                Don&#39;t have an account? <Link to="/auth/register">Sign up</Link>
                            </p>
                        </div>
                        <div className="col-12 mt-4 text-center">
                            <p className="login__privacy-text">
                                This site is protected by reCAPTCHA and the Google Privacy
                                Policy and Terms of Service apply.</p>
                        </div>
                    </div>

                </AuthContainer>
                {term === 'true' && <LoginToast /> }
            </div>
        </>
    )
}


export default React.memo(LoginScreen);

