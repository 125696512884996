
import { Reducer } from 'redux';
import moment from 'moment';

import { types } from 'types/types';
import { IEvent } from 'interfaces/eventInfo';


interface EventState {
    events: IEvent[];
}

type EventAction = {
    type: string;
    payload?: any;
}

const initialValues = {
    events: [
        {
            id: new Date().getTime(),
            title: 'Stripe: QBR AJ <> John',
            start: moment().toDate(),
            end: moment().add(1, 'hours').toDate(),
        }
    ],
};

export const eventsReducer: Reducer<EventState, EventAction> =
    (state: EventState = initialValues, action: EventAction) => {
        switch (action.type) {
            case types.eventsAdd:
                return {
                    ...state,
                    events: [...state.events, action.payload],
                }
            case types.eventsUpdate:
                return {
                    ...state,
                    events: state.events.map(item => {
                        if (item.id === action.payload.id) {
                            return {
                                ...item,
                                ...action.payload,
                            }
                        }
                        return item;
                    }
                    ),
                }
            default:
                return state;
        }
    }