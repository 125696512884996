
import { AccountsInfo } from 'interfaces/accountsInfo';
import { Reducer } from 'redux';
import { types } from 'types/types';

interface AccountsState {
    activeAccount?: AccountsInfo;
}

type AccountsAction = {
    type: string;
    payload?: AccountsInfo;
}

export const accountsReducer: Reducer<AccountsState, AccountsAction> =
    (state: AccountsState = {
        activeAccount: undefined
    }, action: AccountsAction) => {
        switch (action.type) {
            case types.accountsSetActive:
                return {
                    ...state,
                    activeAccount: action.payload
                }

            default:
                return state;
        }
    }