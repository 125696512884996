import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { startResetPassword } from 'actions/auth';

const ForgotPasswordForm = () => {

    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();

    const onSubmit = (data: { email: string}) => {
        const { email } = data;
        dispatch(startResetPassword(email));
    }

    return (
        <form className="mt-2 login__form mb-5 pb-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-12 mt-4">
                    <label
                        htmlFor="email"
                        className="form-control-label login__label">Email address</label>
                    <input
                        id="email"
                        type="email"
                        className={`form-control ${errors.email && 'is-invalid'}`}
                        {...register('email', { required: true, pattern: /^\S+@\S+$/i })} />
                </div>
                <div className="col-12 mt-4 d-grid gap-2">
                    <button 
                        className="btn btn-block text-center button__blue"
                        disabled={isSubmitting}>Send Reset Instructions</button>
                </div>
            </div>
        </form>
    )
}

export default React.memo(ForgotPasswordForm);
