import React from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

import Logo from 'assets/logo.png';
import GoogleIcon from 'assets/google.svg';
import RegisterForm from './components/RegisterForm';
import RegisterTopNavigation from './components/RegisterTopNavigation';
import AuthContainer from 'shared/components/AuthContainer';
import CenterRow from 'shared/components/CenterRow';
import Divider from 'shared/components/Divider';

const GOOGLE_CLIENT_ID = (process.env.REACT_APP_GOOGLE_CLIENT_ID as string);


const RegisterScreen = () => {


    const responseGoogle = (response: any) => {
        console.log(response);

    }

    const onFailure = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        console.log(response);
    }

    return (
        <>
            <RegisterTopNavigation />
            <div className="login__content">
                <AuthContainer>
                    <img src={Logo} alt="Handraise Logo" className="mt-3" width={221} />
                    <p className="login__small-text">Keep every customer.</p>
                    <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        render={renderProps => (
                            <button
                                className="btn btn-block mt-4 login__btn-google"
                                onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                <img src={GoogleIcon} alt="Google Logo" className="me-2" />
                                Sign up with Google
                            </button>
                        )}
                        buttonText="Sign in with Google"
                        onSuccess={responseGoogle}
                        onFailure={onFailure}
                        cookiePolicy={'single_host_origin'}
                    />
                    <CenterRow className="mt-4">
                        <Divider />
                        <div className="login__separator-text">or</div>
                        <Divider />
                    </CenterRow>
                    <RegisterForm />
                    <div className="row login__custom-container">
                        <div className="col-12 mt-5 text-center">
                            <p className="login__privacy-text">
                                This site is protected by reCAPTCHA and the Google Privacy
                                Policy and Terms of Service apply.</p>
                        </div>
                    </div>

                </AuthContainer>
            </div>
        </>
    )
}

export default React.memo(RegisterScreen);
