export const DealsData = [
    {
        id: 1,
        title: 'Closed Lost',
        actions: [],
    },
    {
        id: 2,
        title: 'Qualification',
        actions: [],
    },
    {
        id: 3,
        title: 'Presentation',
        actions: [],
    },
    {
        id: 4,
        title: 'Proposal/Quote',
        actions: [],
    },
    {
        id: 5,
        title: 'Verbal',
        actions: [],
    },
    {
        id: 6,
        title: 'Negotiation/Legal',
        actions: [],
    },
    {
        id: 7,
        title: 'Closed Won',
        actions: [],
    }
];