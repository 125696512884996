import React from 'react';
import PropTypes from 'prop-types';

import { Navigate } from 'react-router';

import { usePageViews } from '../hooks/usePageViews';

export const PublicRoute = ({
    isAuthenticated,
    children,
}: { isAuthenticated: boolean, children: JSX.Element }) => {
    usePageViews();
    return isAuthenticated ? <Navigate to="/" /> : children;

}

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
}
