import { lazy, LazyExoticComponent } from 'react';

type JSXComponent = () => JSX.Element;

interface Route {
    to: string;
    path: string;
    name: string;
    Component: LazyExoticComponent<JSXComponent> | JSXComponent;
}

const SchedulePage = lazy(() => import(/* webpackChunkName: "Schedule" */ '../modules/schedule/ScheduleScreen'));
const TasksPage = lazy(() => import(/* webpackChunkName: "Tasks" */ '../modules/tasks/TaskScreen'));
const MessagesPage = lazy(() => import(/* webpackChunkName: "Messages" */ '../modules/messages/MessagesScreen'));
const DealsPage = lazy(() => import(/* webpackChunkName: "Deals" */ '../modules/deals/DealsScreen'));
const DealsBoardSettings =
    lazy(() => import(/* webpackChunkName: "Deals Settings" */ '../modules/deals-settings/DealsBoardSettings'));
const ForecastPage = lazy(() => import(/* webpackChunkName: "Forecast" */ '../modules/forecast/ForecastScreen'));
const AccountsRoutes = lazy(() => import(/* webpackChunkName: "Accounts" */ '../modules/accounts/AccountsScreen'));
const SettingsPage = lazy(() => import(/* webpackChunkName: "Settings" */ '../modules/settings/SettingsScreen'));
const ReportsPage = lazy(() => import(/* webpackChunkName: "Reports" */ '../modules/reports/ReportsScreen'));
const ReportsBuilderPage =
    lazy(() => import(/* webpackChunkName: "Reports Builder" */ '../modules/reportBuilder/ReportBuilderScreen'));
const AnalyticsPage =
    lazy(() => import(/* webpackChunkName: "Analytics" */ '../modules/analytics/AnalyticsScreen'));
const HelpPage =
    lazy(() => import(/* webpackChunkName: "Help" */ '../modules/help/HelpScreen'));
const SingleDisplayPage =
    lazy(() =>
        import(
            /* webpackChunkName: "Single Display" */
            '../modules/settings/components/displays/components/single/SingleDisplay')
    );
const HomePage = lazy(() => import(/* webpackChunkName: "Home" */ '../modules/home/HomeScreen'));
const LeaderBoardPage =
    lazy(() => import(/* webpackChunkName: "LeaderBoard" */ '../modules/leaderboard/LeaderBoardScreen'));




export const routes: Route[] = [
    {
        to: '/',
        path: '/',
        name: 'Home',
        Component: HomePage,
    },
    {
        path: '/accounts',
        to: '/accounts/',
        Component: AccountsRoutes,
        name: 'Accounts',
    },
    {
        to: '/schedule',
        path: 'schedule',
        name: 'Schedule',
        Component: SchedulePage,
    },
    {
        to: '/tasks',
        path: 'tasks',
        name: 'Tasks',
        Component: TasksPage,
    },
    {
        to: '/messages',
        path: 'messages',
        name: 'Messages',
        Component: MessagesPage,
    },
    {
        to: '/deals',
        path: 'deals',
        name: 'Deals',
        Component: DealsPage,
    },
    {
        to: '/deals-settings',
        path: 'deals-settings',
        name: 'Deals Settings',
        Component: DealsBoardSettings,
    },
    {
        to: '/forecast',
        path: 'forecast',
        name: 'Forecast',
        Component: ForecastPage,
    },

    {
        to: '/settings',
        path: 'settings',
        name: 'Settings',
        Component: SettingsPage,
    },
    {
        to: '/reports',
        path: 'reports',
        name: 'Reports',
        Component: ReportsPage,
    },
    {
        to: '/reports/reportBuilder/:reportTemplateId',
        path: 'reports/reportBuilder/:reportTemplateId',
        name: 'Report Builder',
        Component: ReportsBuilderPage,
    },
    {
        to: '/analytics',
        path: 'analytics',
        name: 'Analytics',
        Component: AnalyticsPage,
    },
    {
        to: '/help',
        path: 'help',
        name: 'Help',
        Component: HelpPage,
    },
    {
        to: '/displays/:id',
        path: 'displays/:id',
        name: 'Single Display',
        Component: SingleDisplayPage,
    },
    {
        to: '/leaderboard',
        path: 'leaderboard',
        name: 'Leaderboard',
        Component: LeaderBoardPage,
    }
];