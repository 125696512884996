import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import {
    authReducer,
    dashboardReducer,
    dealReducer,
    reportBuilderReducer,
    uiReducer,
    eventsReducer,
    accountsReducer,
} from 'reducers';



const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    dashboard: dashboardReducer,
    reportBuilder: reportBuilderReducer,
    deal: dealReducer,
    events: eventsReducer,
    accounts: accountsReducer,
});

export const store = createStore(
        reducers,
        composeEnhancers(
            applyMiddleware(thunk)
        )
);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch