import React, { forwardRef, HTMLProps } from 'react';

interface InputProps extends HTMLProps<HTMLInputElement> {
  contentClassName?: string;
}

const SearchInput = forwardRef<HTMLInputElement, InputProps>(
  // eslint-disable-next-line react/prop-types
  ({contentClassName = '', className, ...restProps}, ref) => {
    return (
      <div className={`input-group mb-3 accounts__search-box-wrapper ${contentClassName}`}>
        <span className="input-group-text">
            <i className="fas fa-search"></i>
        </span>
        <input ref={ref} type="search" className={`form-control accounts__search-box ${className}`} {...restProps} />
      </div>
    );
  },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
