import Swal from 'sweetalert2';

import { types } from 'types/types';
import { AppDispatch } from 'store/store';
import { fetchWithoutToken } from 'helpers/fetch';
import { UserInfo } from 'interfaces/userInfo';

export const login = (user: UserInfo) => ({
    type: types.authLogin,
    payload: user

});


export const startLogin = (email: string, password: string) => {
    return async (dispatch: AppDispatch) => {

        try {
            const resp = await fetchWithoutToken('auth/login/', { email, password }, 'POST');
            const body = await resp.json();

            if (body.access_token) {
                localStorage.setItem('access_token', body.access_token);
                localStorage.setItem('refresh_token', body.refresh_token);
                localStorage.setItem('handraise_user', JSON.stringify(body.user));

                dispatch(login(body.user))
            } else {
                Swal.fire('Error', body.non_field_errors[0], 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Credentials are not valid', 'error');
        }
    }
}

export const startRegister = (
        email: string,
        password1: string,
        password2: string,
        first_name: string,
        last_name: string) => {
    return async (dispatch: AppDispatch) => {
        console.log(first_name, last_name);
        try {
            const resp = await fetchWithoutToken('auth/registration/', { email, password1, password2 }, 'POST');
            const body = await resp.json();

            if (body.access_token) {
                localStorage.setItem('access_token', body.access_token);
                localStorage.setItem('refresh_token', body.refresh_token);
                localStorage.setItem('handraise_user', JSON.stringify(body.user));

                dispatch(login(body.user))
            } else {
                Swal.fire('Error', body.email[0], 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'An error occurred while creating the user, please try again later.', 'error');
        }
    }
}

export const startGoogleLogin = (access_token: string, id_token: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            const resp = await fetchWithoutToken('auth/gauth/', { access_token, id_token }, 'POST');
            const body = await resp.json();
            if (body.access_token) {
                localStorage.setItem('access_token', body.access_token);
                localStorage.setItem('refresh_token', body.refresh_token);
                localStorage.setItem('handraise_user', JSON.stringify(body.user));

                dispatch(login(body.user))
            } else {
                Swal.fire('Error', body.non_field_errors[0], 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'An error occurred while creating the user, please try again later.', 'error');
        }
    }
}

export const startResetPassword = (email: string) => {
    return async() => {
        try {
            const resp = await fetchWithoutToken('auth/password/reset/', { email }, 'POST');
            const body = await resp.json();
            if (body.detail) {
                Swal.fire('Success', body.detail, 'success');
            } else {
                Swal.fire('Error', 'An error occurred, please try again later.', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'An error occurred, please try again later.', 'error');
        }
    }
}

export const logout = () => ({
    type: types.authLogout,
});


export const startLogout = () => {
    return (dispatch: AppDispatch) => {
        localStorage.clear();
        dispatch(logout());
    }
}

export const startChecking = () => {
    return async (dispatch: AppDispatch) => {

        try {
            const userInStorage = localStorage.getItem('handraise_user');
            const user = userInStorage ? JSON.parse(userInStorage) : null;
            if (!user) {
                dispatch(checkingFinish());
                return;
            }
            const refreshToken = localStorage.getItem('refresh_token');
            const resp = await fetchWithoutToken('auth/token/refresh/', { refresh: refreshToken }, 'POST');
            const body = await resp.json();


            if (body.access && user) {
                localStorage.setItem('access_token', body.access);
                localStorage.setItem('refresh_token', body.refresh);

                dispatch(login(user))
            } else {
                dispatch(checkingFinish());
            }
        } catch (error) {
            dispatch(checkingFinish());
        }

    }
}

const checkingFinish = () => ({ type: types.authCheckingFinish });
