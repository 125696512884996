import React from 'react';
import ReactDOM from 'react-dom';
import { HandraiseApp } from './HandraiseApp';
import './styles/styles.scss';

ReactDOM.render(
  <HandraiseApp />, 
  document.getElementById('root')
);

