import React from 'react';
import { NavLink } from 'react-router-dom';
import LogoWhite from 'assets/logo-white.png';
import LogoIconOnly from 'assets/handraise-icon.svg';
import AccountsIcon from 'assets/user-group.svg';
import DisplaysIcon from 'assets/code.svg';
import ReportsIcon from 'assets/chart-bar.svg';
import SupportIcon from 'assets/support.svg';
import CogIcon from 'assets/cog.svg';
import AnalyticsIcon from 'assets/handraise-iconmark.svg';
import CalendarIcon from 'assets/calendar.svg';
import TasksIcon from 'assets/check-circle.svg';
import MailIcon from 'assets/mail.svg';
import CurrencyIcon from 'assets/currency-dollar.svg';
import HomeIcon from 'assets/home.svg';
import FireIcon from 'assets/fire-menu.svg';

const Sidebar = () => {

    return (
        <div className="border-end pt-3 " id="sidebar-wrapper">
            <div className="sidebar-heading text-center pb-4">
                <img className='sidebar__logo-image' src={LogoWhite} alt="Handraise Logo" width={117} />
                <img className='sidebar__logo-icon' src={LogoIconOnly} alt="Handraise Logo" />
            </div>
            <div className="d-flex sidebar__content">
                <div className="list-group list-group-flush">
                <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/">
                        <img src={HomeIcon} alt="Home Icon" />
                        Home
                    </NavLink>
                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/accounts">
                        <img src={AccountsIcon} alt="Accounts Icon" />
                        Accounts
                    </NavLink>
                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/schedule">
                        <img src={CalendarIcon} alt="Schedule Icon" />
                        Schedule
                    </NavLink>
                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/tasks">
                        <img src={TasksIcon} alt="Tasks Icon" />
                        Tasks
                    </NavLink>

                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/messages">
                        <img src={MailIcon} alt="Messages Icon" />
                        Messages
                    </NavLink>
                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/reports">
                        <img src={ReportsIcon} alt="Reports Icon" />
                        Reports
                    </NavLink>
                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/deals">
                        <img src={CurrencyIcon} alt="Deals Icon" />
                        Deals
                    </NavLink>
                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/forecast">
                        <img src={DisplaysIcon} alt="Deals Icon" />
                        Forecast
                    </NavLink>
                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/leaderboard">
                        <img src={FireIcon} alt="Deals Icon" />
                        Leaderboard
                    </NavLink>

                </div>

                <div className="list-group list-group-flush align-self-end sidebar__bottom-section">
                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/analytics">
                        <img src={AnalyticsIcon} alt="Analytics Icon" />
                        Analytics
                    </NavLink>
                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/settings">
                        <img src={CogIcon} alt="Cog Icon" />
                        Settings
                    </NavLink>
                    <NavLink className="list-group-item list-group-item-action p-3 sidebar__option" to="/help">
                        <img src={SupportIcon} alt="Support Icon" />
                        Help &amp; Getting Started
                    </NavLink>
                </div>
            </div>

        </div>
    )
}

export default React.memo(Sidebar);
