import React from 'react';

interface Props {
    style?: React.CSSProperties;
}

export const CustomLoader = ({ style }: Props) => {
    return (
        <div className='loader__wrapper' style={style}>
            <div className="fa-3x">
                <i className="fas fa-circle-notch fa-spin"></i>
            </div>
        </div>
    )
}
