import React, { Suspense } from 'react';
import {
    Routes,
    Route,
    useLocation,
    Navigate,
} from 'react-router-dom';
import { LazyLoader } from 'shared/ui/LazyLoader';
import NavbarUi from 'shared/ui/NavbarUi';
import Sidebar from 'shared/ui/Sidebar';
import { routes } from './routes';

const Routes_Full_Page = [
    'reports/reportBuilder'
];

export const DashboardRoutes = () => {

    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
    const [isFullPage, setIsFullPage] = React.useState(false);

    React.useEffect(() => {
        setIsFullPage(false);
        for (let i = 0; i < Routes_Full_Page.length; i++) {
            if (location.pathname.includes(Routes_Full_Page[i])) {
                setIsFullPage(true);
            }
        }
    }, [location]);

    const handleSidebarToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        document.body.classList.toggle('sb-sidenav-toggled');

        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <div className="d-flex" id="wrapper">
            <Sidebar />
            <button className='button__sidebar-toggle' onClick={handleSidebarToggle}>
                <i className={`fas ${!isSidebarOpen ? 'fa-chevron-right' : 'fa-chevron-left'}`}></i>
            </button>
            <div id="page-content-wrapper" className={isFullPage ? 'fullPage' : undefined}>
                {!isFullPage && <NavbarUi />}
                <Suspense fallback={<LazyLoader delay={300} />}>

                    <Routes>
                        {routes.map((route) => (
                            <Route key={route.to} path={route.path} element={< route.Component />} />

                        ))}
                        <Route path="/*" element={<Navigate to={routes[0].to} replace />} />
                    </Routes>
                </Suspense>
            </div>
        </div>
    );
}
