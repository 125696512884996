import React from 'react';
import {
    Routes,
    Route,
} from 'react-router-dom';
import ForgotPassword from '../modules/auth/forgot-password/ForgotPassword';
import LoginScreen from '../modules/auth/login/LoginScreen';
import RegisterScreen from '../modules/auth/register/RegisterScreen';

export const AuthRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/register" element={<RegisterScreen />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
    );
}
