import React from 'react';
import Logo from 'assets/logo.png';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ForgotPasswordNav from './components/ForgotPasswordNav';
import AuthContainer from 'shared/components/AuthContainer';


const ForgotPassword = () => {
    return (
        <>
            <ForgotPasswordNav />
            <div className="login__content">
                <AuthContainer>
                    <img src={Logo} alt="Handraise Logo" className="mt-3" width={221} />
                    <p className="login__small-text">Keep every customer.</p>
                    <div className="mt-5">
                        <h3 className="login__forgot-title">
                            Forgot your password?
                        </h3>
                        <p className="login__text-forgot mt-3">
                            Enter the email address you used when you joined and
                            we&apos;ll send you instructions to reset your password.
                            <br /><br />
                            For security reasons, we do NOT store your password.
                            So rest assured that we will never send your password via email.
                        </p>
                    </div>
                    <ForgotPasswordForm />

                </AuthContainer>
            </div>
        </>
    )
}

export default React.memo(ForgotPassword);
