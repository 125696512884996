import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'

export const usePageViews = () => {
  const location = useLocation()
  useEffect(
    () => {
      /*
        Segment page login
        https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
      */
      window.analytics.page(location.pathname)
    },
    [location]
  )
}
