import React, { useEffect, useState } from 'react';
import { CustomLoader } from './CustomLoader';

export interface LazyLoaderProps {
    delay?: number;
}

export const LazyLoader = ({
    delay = 250,
}: LazyLoaderProps) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    return show ? <CustomLoader style={{
        width: '100%',
    }} /> : null;
};

