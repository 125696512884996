import {Reducer} from 'redux';

import { SlideInfo } from 'interfaces/slideInfo';
import { types } from 'types/types';
import { SelectedReportBuilderTool } from 'helpers/types';

interface ReportBuilderState {
  slides: SlideInfo[];
  selectedCurrentSlide?: SlideInfo | null;
  historySelectedSlideChanges: Array<SlideInfo>;
  historySelectedSlideStep: number;
  zoomLevel: number;
  selectedTool?: Array<SelectedReportBuilderTool> | null;
}

type ReportBuilderAction =
    | { type: string; payload?: any };

export const reportBuilderReducer: Reducer<ReportBuilderState, ReportBuilderAction> =
 (
    state: ReportBuilderState = {
     slides: [{id: 'rb-00-00-1', slideNumber: 1}],
     selectedCurrentSlide: null,
     historySelectedSlideChanges: [],
     historySelectedSlideStep: 0,
     zoomLevel: 100,
     selectedTool: null,
    },
    action: ReportBuilderAction) => {
   switch (action.type) {
     case types.reportBuilderLoadSlides:
       return {
         ...state,
         slides: [...action.payload],
       };
     case types.reportBuilderSelectCurrentSlide: {
       return {
         ...state,
         selectedCurrentSlide: {...action.payload},
         historySelectedSlideChanges: [action.payload],
         historySelectedSlideStep: 0,
       };
      }
      case types.reportBuilderUpdateSlide: {
        const updatedSlide = action.payload as SlideInfo;
        const slicedHistorySlideChanges = state.historySelectedSlideChanges.slice(
          0,
          state.historySelectedSlideStep + 1
        );
        const historySelectedSlideChanges = [...slicedHistorySlideChanges, updatedSlide];
        return {
          ...state,
          selectedCurrentSlide: updatedSlide,
          historySelectedSlideStep: state.historySelectedSlideStep + 1,
          historySelectedSlideChanges,
        };
      }
      case types.reportBuilderZoomSlide:
        return {
          ...state,
          zoomLevel: action.payload,
        };
      case types.reportBuilderSelectTool:{
        const {newTool, add} = action.payload;
        let selectedTool: Array<SelectedReportBuilderTool> | null = null;
        if (!add && newTool) {
          selectedTool = [];
          selectedTool.push(newTool);
        }
        if (
          add &&
          (!state.selectedTool ||
            (state.selectedTool && state.selectedTool.length <= 0))
        ) {
          selectedTool = [];
          selectedTool.push(newTool);
        }
        if (newTool && add && state.selectedTool) {
          selectedTool = state.selectedTool;
          if (!state.selectedTool.some(
            currentTool => currentTool?.type === newTool.type &&
            currentTool?.tool.id === newTool.tool.id
          )) {
            selectedTool.push(newTool);
          }
        }
        return {
          ...state,
          selectedTool,
        };
      }
      case types.reportBuilderUpdateSelectedSlide: {
        const updatedSlide: SlideInfo = action.payload;
        const updatedSlides = state.slides.map((slide) => {
          return slide.id === updatedSlide.id ? updatedSlide : slide;
        });
        return {
          ...state,
          slides: updatedSlides,
        };
      }
      case types.reportBuilderUndoSelectedSlideChange: {
        if (state.historySelectedSlideStep === 0) {
          return { ...state};
        }
        const historyStep = state.historySelectedSlideStep - 1;
        const previousSlideChange = state.historySelectedSlideChanges[historyStep];
        return {
          ...state,
          selectedCurrentSlide: previousSlideChange,
          historySelectedSlideStep: historyStep,
        };
      }
      case types.reportBuilderRedoSelectedSlideChange: {
        if (state.historySelectedSlideStep === state.historySelectedSlideChanges.length - 1) {
          return { ...state};
        }
        const historyStep = state.historySelectedSlideStep + 1;
        const nextSlideChange = state.historySelectedSlideChanges[historyStep];
        return {
          ...state,
          selectedCurrentSlide: nextSlideChange,
          historySelectedSlideStep: historyStep,
        };
      }
     default:
       return state;
   }
 };
