import React from 'react';
import { Provider } from 'react-redux';
import { AppRouter } from './routers/AppRouter';
import { store } from './store/store';

declare global {
  interface Window { analytics: any; }
}

export const HandraiseApp = () => {
    return (
        <Provider store={store}>
            <AppRouter />
        </Provider>
    );
};
