
import { types } from 'types/types';

interface DashboardState {
    showEditMode?: boolean;
    showChart?: boolean;
}

type DashboardAction = {
    type: string;
}

export const dashboardReducer =
    (state: DashboardState = { showEditMode: false }, action: DashboardAction) => {
        switch (action.type) {
            case types.dashboardShowEditMode:
                return {
                    ...state,
                    showEditMode: true
                }
            case types.dashboardHideEditMode:
                return {
                    ...state,
                    showEditMode: false
                }

            case types.dashboardShowChart:
                return {
                    ...state,
                    showChart: true
                }

            default:
                return state;
        }
    }