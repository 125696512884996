import React from 'react';
import PropTypes from 'prop-types';

import { Navigate } from 'react-router';

import { usePageViews } from '../hooks/usePageViews';

export const PrivateRoute = ({
    isAuthenticated,
    children,
}: { isAuthenticated: boolean, children: JSX.Element }) => {
    usePageViews();
    return isAuthenticated ? children : <Navigate to="/auth/login" />;
}

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
}