import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

const LoginForm = () => {

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();
    const navigate = useNavigate();

    const onSubmit = (data: { email: string, password: string }) => {
        const { email, password } = data;
        console.log(email, password);
        navigate('/today');

    }

    return (
        <form className="mt-2 login__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-12 mt-4">
                    <label
                        htmlFor="email"
                        className="form-control-label login__label">Email address</label>
                    <input
                        id="email"
                        type="email"
                        className={`form-control ${errors.email && 'is-invalid'}`}
                        {...register('email', { required: true, pattern: /^\S+@\S+$/i })} />
                </div>
                <div className="col-12 mt-4">
                    <label
                        htmlFor="password"
                        className="form-control-label login__label">Password</label>
                    <input
                        id="password"
                        type="password"
                        className={`form-control ${errors.password && 'is-invalid'}`}
                        {...register('password', { required: true })} />
                </div>
                <div className="col-12 mt-3">
                    <Link to="/auth/forgot-password" className="login__forgot-password">Forgot your password?</Link>
                </div>
                <div className="col-12 mt-4 d-grid gap-2">
                    <button
                        className="btn btn-block text-center button__blue"
                        disabled={isSubmitting}
                    >Login</button>
                </div>
            </div>
        </form>
    )
}

export default React.memo(LoginForm);
