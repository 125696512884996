import React from 'react';

export const LoginToast = () => {
    return (
        <div className='login__confirmation-toast'>
            If this email address was used to create an account,
            instructions to reset your password will be sent to you. Please check your email.
        </div>
    )
}
