
import { types } from 'types/types';
import { UserInfo } from 'interfaces/userInfo';

interface AuthState {
    user?: UserInfo;
    checking?: boolean;
}

type AuthAction =
    | { type: string; payload?: { user: UserInfo; } }

export const authReducer = (
    state: AuthState = { checking: true },
    action: AuthAction) => {
    switch (action.type) {
        case types.authLogin:
            return {
                ...state,
                user: { ...action.payload },
                checking: false
            }
        case types.authLogout:
            return {
                checking: false
            }
        case types.authCheckingFinish:
            return {
                ...state,
                checking: false
            }

        default:
            return state;
    }
}